/* eslint-disable */
// https://dev2.bhsn.it/api/aistudio/swagger-ui/index.html
import { toAxiosOptions, createServiceAxiosInstance } from '$lib/utils';
import type { AxiosPromise } from 'axios';

export const axiosInstance = createServiceAxiosInstance('aistudio');

/**
 * @returns string OK
 * @throws ApiError
 */
export function hello(requestConfig: RequestConfig = {}): AxiosPromise<string> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/hello',
        }),
        ...requestConfig,
    });
}
/**
 * Rag 쿼리 v1. SSE(Server-Sent Events) 방식.
 * @returns AiStudioRagQueryDto 결과를 실시간 event-stream으로 보낸다.
 * @throws ApiError
 */
export function processQuery(
    {
        requestBody,
    }: {
        requestBody: AiStudioRagQueryForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<AiStudioRagQueryDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/rags/query/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * Rag 파일 다운로드
 * @returns AiStudioStreamingResponseBody OK
 * @throws ApiError
 */
export function downloadDocument(
    {
        collectionId,
        documentId,
    }: {
        /**
         * 데이터베이스 ID
         */
        collectionId: string;
        /**
         * 도큐먼트 ID
         */
        documentId: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<AiStudioStreamingResponseBody> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/rags/{collectionId}/{documentId}/download/v1',
            path: {
                collectionId: collectionId,
                documentId: documentId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 파일 업로드
 * @returns AiStudioResponse OK
 * @throws ApiError
 */
export function uploadDocument(
    {
        categoryId,
        formData,
    }: {
        /**
         * 카테고리 ID
         */
        categoryId: number;
        formData?: {
            form: AiStudioAiCategoryDocumentCreateForm;
            /**
             * file
             */
            file: Blob;
        };
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<AiStudioResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/categories/{categoryId}/documents/by-file/v1',
            path: {
                categoryId: categoryId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        }),
        ...requestConfig,
    });
}
/**
 * 워크스페이스별 카테고리 목록 조회
 * @returns AiStudioResponseListAiCategoryDto OK
 * @throws ApiError
 */
export function getWorkspaceCategories(requestConfig: RequestConfig = {}): AxiosPromise<AiStudioResponseListAiCategoryDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/categories/v1',
        }),
        ...requestConfig,
    });
}
/**
 * 카테고리 생성 (custom)
 * @returns AiStudioResponseAiCategoryDto OK
 * @throws ApiError
 */
export function createCategory(
    {
        requestBody,
    }: {
        requestBody: AiStudioAiCategoryCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<AiStudioResponseAiCategoryDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/categories/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * AI 모델 목록 조회
 * @returns AiStudioResponseListAiModelDto OK
 * @throws ApiError
 */
export function getModels(requestConfig: RequestConfig = {}): AxiosPromise<AiStudioResponseListAiModelDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/models/v1',
        }),
        ...requestConfig,
    });
}
/**
 * LLM 호출량 조회
 * @returns AiStudioResponseListDashboardLlmDto OK
 * @throws ApiError
 */
export function getLlmSummary(
    {
        dateStart,
        dateEnd,
    }: {
        /**
         * 조회 시작일
         */
        dateStart?: string;
        /**
         * 조회 종료일
         */
        dateEnd?: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<AiStudioResponseListDashboardLlmDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/dashboards/llm/v1',
            query: {
                dateStart: dateStart,
                dateEnd: dateEnd,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 파일 다운로드
 * @returns AiStudioStreamingResponseBody OK
 * @throws ApiError
 */
export function downloadDocument1(
    {
        categoryId,
        documentId,
    }: {
        /**
         * 카테고리 ID
         */
        categoryId: number;
        /**
         * 도큐먼트 ID
         */
        documentId: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<AiStudioStreamingResponseBody> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/categories/{categoryId}/documents/{documentId}/download/v1',
            path: {
                categoryId: categoryId,
                documentId: documentId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 파일 목록 조회 (paging)
 * @returns AiStudioResponsePageAiCategoryDocumentDto OK
 * @throws ApiError
 */
export function pageDocuments(
    {
        categoryId,
        pageable,
    }: {
        /**
         * 카테고리 ID
         */
        categoryId: number;
        pageable: AiStudioPageable;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<AiStudioResponsePageAiCategoryDocumentDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/categories/{categoryId}/documents/v1',
            path: {
                categoryId: categoryId,
            },
            query: {
                pageable: pageable,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 카테고리 목록 페이징 조회
 * @returns AiStudioResponsePageAiCategoryDto OK
 * @throws ApiError
 */
export function pageByCondition(
    {
        searchKeyword,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<AiStudioResponsePageAiCategoryDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/categories/search/v1',
            query: {
                searchKeyword: searchKeyword,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
