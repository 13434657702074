import { get } from 'svelte/store';
import { masterState } from '$stores/master';

export function transformUrl(url: string) {
    const locale = get(masterState).paramLocale;
    if (!locale) return url;
    else if (url.startsWith(`/${locale}`) || url.startsWith('http')) return url;
    return `/${locale}` + url;
}

export function serializeParams(paramObj: Record<string, any>) {
    const params = new URLSearchParams();
    let flattenObj = {};

    for (const key in paramObj) {
        if (!Array.isArray(paramObj[key]) && typeof paramObj[key] === 'object') {
            flattenObj = {
                ...flattenObj,
                ...paramObj[key],
            };
        } else {
            flattenObj[key] = paramObj[key];
        }
    }

    function recursive(paramObj: Record<string, any>, prefix = '') {
        for (const key in paramObj) {
            const value = paramObj[key];
            const prefixedKey = prefix ? `${prefix || ''}${key}` : key;

            if (Array.isArray(value) && value.length > 0) {
                for (const item of value) {
                    params.append(prefixedKey, item);
                }
            } else if (value !== null && value !== undefined) {
                if (typeof value === 'object') {
                    recursive(value, key + '.');
                } else {
                    params.append(prefixedKey, value);
                }
            }
        }
    }

    recursive(flattenObj);

    return params.toString();
}
