import { AiStudioService } from '$lib/api';
import { find } from 'lodash-es';
import { derived, writable, type Readable } from 'svelte/store';

interface MetaStore {
    aiCategoriesItems: AiStudioAiCategoryDto[];
    aiWorkspaceCategories: AiStudioAiCategoryDto[];
    aiGeneralCategories: AiStudioAiCategoryDto[];
    aiModels: AiStudioAiModelDto[];
    getHomePathByProductType: (productType: string, workspaceName?: string) => string;
}

let prevWorkspaceName = '';
let prevProductMetaKey = '';

const aiStudioMeta = writable({} as Record<string, any>);

// productType 과 path를 매핑
const productTypeToPath = {
    AI_STUDIO: '/[workspaceName]/',
};

const getProductTypePath = (productType: string) => productTypeToPath?.[productType] || '/';

export const meta: Readable<MetaStore> = derived([aiStudioMeta], ([$ai], set) => {
    set({
        aiCategoriesItems: $ai.aiCategoriesItems,
        aiWorkspaceCategories: $ai.aiWorkspaceCategories,
        aiGeneralCategories: $ai.aiGeneralCategories,
        aiModels: $ai.aiModels,
        getHomePathByProductType: (productType: string, workspaceName?: string) => getProductTypePath(productType).replace('[workspaceName]', workspaceName || prevWorkspaceName),
    });
});

// Actions
export const resetMeta = () => {
    prevWorkspaceName = '';
    prevProductMetaKey = '';

    aiStudioMeta.set({});
};

export const fetchRefreshMetaInfo = async (workspaceName, productType?: keyof typeof productTypeToPath) => {
    const tempProductKey = `${workspaceName}_${productType}`;

    if (!workspaceName || prevProductMetaKey === tempProductKey) return;

    prevWorkspaceName = workspaceName;
    prevProductMetaKey = tempProductKey;

    // if (productType === 'AI_STUDIO') {

    await fetchAiStudioMetaInfo(workspaceName);
    // }
};
async function fetchAiStudioMetaInfo(workspaceName: string) {
    const [categoryResponse, modelResponse] = await Promise.all([AiStudioService.getWorkspaceCategories(), AiStudioService.getModels()]);

    aiStudioMeta.set({
        aiCategoriesItems: categoryResponse.data.data,
        aiWorkspaceCategories: categoryResponse.data.data.filter(category => category.serviceType === 'WORKSPACE'),
        aiGeneralCategories: categoryResponse.data.data.filter(category => category.serviceType === 'GENERAL'),
        aiModels: modelResponse.data.data,
    });
}
