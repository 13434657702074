import axios, { type AxiosInstance, type InternalAxiosRequestConfig } from 'axios';
import { browser, dev } from '$app/environment';
import { goto } from '$app/navigation';
import { PUBLIC_COMMON_API_BASE_PATH, PUBLIC_COMMON_API_HOST, PUBLIC_AI_STUDIO_API_HOST, PUBLIC_AI_STUDIO_API_BASE_PATH } from '$env/static/public';
import { HeimdallService } from '$lib/api';
import { serializeParams, toastDanger, transformUrl } from '$lib/utils';
import { actionClearAuth, actionSignOut, auth } from '$stores/auth';
import { resetMeta } from '$stores/meta';
import * as Sentry from '@sentry/sveltekit';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { locale } from 'svelte-i18n';
import { get } from 'svelte/store';

const STATUS_UNAUTHORIZED = 401;
const STATUS_FORBIDDEN = 403;
const ERROR_ID = crypto.randomUUID();

let logoutPromise: Promise<void> | null = null;
let isRefreshing = false;

export function setupDefault(instance: AxiosInstance) {
    instance.defaults.paramsSerializer = function (paramObj: Record<string, any>) {
        // axios 에서 query string을 만들 때, 배열이 값으로 들어가면, 'arr[]=1&arr[]=2' 이런식으로 만들어지는데, 이걸 'arr=1&arr=2' 이런식으로 만들어주는 serializer
        // 추가적으로, object가 값으로 들어가면, 'obj=[object Object]' 이런식으로 만들어지는데, 이걸 'obj.a=b&obj.c=d' 이런식으로 만들어주는 serializer
        // object일 경우, 1차 object는 flatten되어 prefix가 붙지 않도록 무시됨.
        // ex) { a: 'b', c: { d: 'e' } } => 'a=b&d=e'
        return serializeParams(paramObj);
    };
}

// 새로만드는 axios instance에 기본 interceptor를 세팅해주는 함수
export function setupInterceptors(instance: AxiosInstance) {
    instance.interceptors.response.use(
        async function (response) {
            // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
            // 응답 데이터가 있는 작업 수행

            const refreshAfter = response.headers['x-refresh-after'] || null;
            if (refreshAfter) {
                localStorage.setItem('refreshTokenTiming', refreshAfter);
            }

            return response;
        },
        async function (error) {
            // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
            // 응답 오류가 있는 작업 수행

            const { url } = error.config;

            const ignoreRedirect = error.config?.ignoreRedirect;

            // 인증 오류
            // 브라우저에서만 동작
            // 무한 요청을 막기 위해 로그아웃 중일 때는 로그아웃 요청을 보내지 않음
            if (browser && !logoutPromise) {
                let newError;
                if (error.response?.status === STATUS_UNAUTHORIZED && !ignoreRedirect && ['/login/v', '/logout/v', '/refresh/v'].every(path => !url?.includes(path))) {
                    logoutPromise = actionSignOut(error.config.fallbackPath)
                        .then(() => {
                            newError = new Error('로그인 정보가 만료되었습니다. <br />다시 로그인해주세요.');
                            error.config.ignoreSentry = true;
                        })
                        .finally(() => {
                            logoutPromise = null; // 로그아웃 프로세스 완료 후, Promise를 초기화
                        });
                    await logoutPromise; // 로그아웃 프로세스가 완료될 때까지 기다림
                } else if (error.response?.status === STATUS_FORBIDDEN) {
                    // 권한 오류
                    if (url?.includes('/refresh/v')) {
                        newError = new Error('로그인 정보가 만료되었습니다. <br />다시 로그인해주세요.');
                        error.config.ignoreSentry = true;
                    } else {
                        newError = new Error('접근 권한이 없습니다.');
                    }
                } else {
                    showError(error);
                    return Promise.reject(error);
                }

                newError.config = error.config;
                showError(newError);

                return Promise.reject(newError);
            }

            return Promise.reject(error);
        },
    );
}

// baseURL이 없는 axios instance를 만드는 함수
export function createAxiosInstance(options = {}) {
    const instance = axios.create(options);
    setupDefault(instance);
    setupInterceptors(instance);
    return instance;
}

export const axiosInstance = createAxiosInstance({
    timeout: 3000,
});

export function createServiceAxiosInstance(serviceRoute: string, options = {}) {
    const baseURL = `${PUBLIC_COMMON_API_HOST}${PUBLIC_COMMON_API_BASE_PATH}${serviceRoute}`;
    const instance = createAxiosInstance({ baseURL, timeout: 10000, withCredentials: true, ...options });

    setupRequestInterceptors(instance);

    return instance;
}

export function createAiServiceAxiosInstance(serviceRoute: string, options = {}) {
    const baseURL = `${PUBLIC_AI_STUDIO_API_HOST}`;
    const instance = createAxiosInstance({ baseURL, timeout: 10000, withCredentials: true, ...options });

    instance.interceptors.request.use(
        async function (config: InternalAxiosRequestConfig) {
            config.headers['X-Auth-Token'] = 'u3NpXQAdEBfMH3X';
            return config;
        },
        function (error) {
            // 요청 에러 핸들링
            return Promise.reject(error);
        },
    );

    return instance;
}

function setupRequestInterceptors(instance: AxiosInstance) {
    instance.interceptors.request.use(
        async function (config: InternalAxiosRequestConfig) {
            const { url } = config;

            // 토큰 리프레시 필요 여부 확인 및 수행
            const noAuthNeeded = ['/refresh/v', '/my-info/v', '/login/v', '/logout/v'].some(path => url?.includes(path));
            if (!noAuthNeeded && shouldRefreshToken()) {
                await refreshToken();
            }

            // 공통 헤더 설정
            setCommonHeaders(config);

            return config;
        },
        function (error) {
            // 요청 에러 핸들링
            return Promise.reject(error);
        },
    );
}

function setCommonHeaders(config) {
    const { tenantId, workspaceId } = get(auth);
    const localeValue = get(locale);

    // 추출한 정보를 헤더에 설정
    config.headers['X-Tenant'] = tenantId || 0;
    config.headers['X-Workspace'] = workspaceId || 0;
    config.headers['X-Locale'] = localeValue || 'ko';
}

function shouldRefreshToken() {
    if (!browser) return false; // 브라우저가 아니면 즉시 false 반환 (SSR에서는 리프레시 불필요

    const refreshTokenTiming = localStorage.getItem('refreshTokenTiming');
    if (!refreshTokenTiming) {
        return false; // 리프레시 타이밍 정보가 없으면 즉시 false 반환
    }

    const isLogin = !isEmpty(get(auth).userInfo);
    if (!isLogin) {
        localStorage.removeItem('refreshTokenTiming'); // 로그인 상태가 아니면 리프레시 타이밍 정보를 삭제하고 false 반환
        return false;
    }

    const isTokenExpired = dayjs().isAfter(dayjs(refreshTokenTiming));
    const isTabActive = document.visibilityState === 'visible';

    return isTokenExpired && isTabActive && isLogin; // 토큰이 만료되었고, 탭이 활성 상태이고, 로그인 상태이면 true 반환
}

async function refreshToken() {
    if (isRefreshing) return;
    isRefreshing = true;

    try {
        await HeimdallService.refreshV2();
    } catch (error) {
        actionClearAuth();
        resetMeta();
        Sentry.captureException(error);
        await goto(transformUrl('/sign-in'), { replaceState: true });
    } finally {
        isRefreshing = false;
    }
}

function showError(error) {
    const errorMsg = error.response?.data.message || error.message;
    const status = error.response?.status;

    const ignoreErrorMessage = error.config?.ignoreErrorMessage;
    if (!ignoreErrorMessage) {
        toastDanger(`${status ? `(${status}) ` : ''}${errorMsg}`);
    }

    const ignoreSentry = error.config?.ignoreSentry;
    if (!dev && !ignoreSentry) {
        Sentry.captureException(error, { extra: { ERROR_ID } });
    }
}
