/* eslint-disable */
// https://dev2.bhsn.it/api/heimdall/swagger-ui/index.html
import { toAxiosOptions, createServiceAxiosInstance } from '$lib/utils';
import type { AxiosPromise } from 'axios';

export const axiosInstance = createServiceAxiosInstance('heimdall');

/**
 * 사용자 자격증명 생성 (벌크)
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function createBulkUserCredentials(
    {
        requestBody,
    }: {
        requestBody: HeimdallBulkUserCredentialCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/external/user-credentials/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 자격증명 수정 (재설정 토큰)
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function resetUserCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallUserCredentialResetForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/user-credentials/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 자격증명 생성
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function createUserCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallUserCredentialCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/user-credentials/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 자격증명 제거
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function deleteUserCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallUserCredentialDeleteForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/user-credentials/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 자격증명 수정 (로그인 상태)
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function updateUserCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallUserCredentialUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PATCH',
            url: '/user-credentials/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 자격증명 강제 수정 (슈퍼어드민 전용)
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function forceResetUserCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallUserCredentialForceResetForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/user-credentials/force/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 서비스 토큰 조회
 * @returns HeimdallResponseServiceToken OK
 * @throws ApiError
 */
export function issueToken(
    {
        requestBody,
    }: {
        requestBody: HeimdallServiceAuthForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponseServiceToken> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/service-auth/tokens/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 재발급 V2
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function refreshV2(requestConfig: RequestConfig = {}): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/refresh/v2',
        }),
        ...requestConfig,
    });
}
/**
 * 재발급
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function refresh(
    {
        lissRft,
    }: {
        lissRft: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/refresh/v1',
            cookies: {
                liss_rft: lissRft,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 비밀번호 초기화
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function resetCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallRedirectMailForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/message/reset-credentials/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 로그아웃
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function logout(requestConfig: RequestConfig = {}): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/logout/v1',
        }),
        ...requestConfig,
    });
}
/**
 * 로그인
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function login(
    {
        requestBody,
    }: {
        requestBody: HeimdallLoginForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/login/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 슈퍼 어드민 전용 로그인
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function loginForSuperAdmin(
    {
        requestBody,
    }: {
        requestBody: HeimdallLoginForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/login/super-admin/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * Impersonated 로그인(사용자 대리 로그인)
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function impersonatedLogin(
    {
        requestBody,
    }: {
        requestBody: HeimdallImpersonatedLoginForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/login/impersonated/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 로그인 (개발용 non-cookie 버전)
 * @returns HeimdallResponseTokenPair OK
 * @throws ApiError
 */
export function loginForDev(
    {
        requestBody,
    }: {
        requestBody: HeimdallLoginForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponseTokenPair> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/login/dev/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 유저 로그인 추적 정보 조회
 * @returns HeimdallResponseListUserLoginTrackDto OK
 * @throws ApiError
 */
export function listUserLoginTracks(
    {
        userIds,
    }: {
        userIds?: Array<number>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponseListUserLoginTrackDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/user-login-tracks/v1',
            query: {
                userIds: userIds,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 테넌트/워크스페이스 별 사용자 권한 목록 조회 V3
 * @returns HeimdallResponseListPermissionDto OK
 * @throws ApiError
 */
export function listWorkspaceUserPermissionsV3(requestConfig: RequestConfig = {}): AxiosPromise<HeimdallResponseListPermissionDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/permissions/v3',
        }),
        ...requestConfig,
    });
}
/**
 * 테넌트/워크스페이스 별 사용자 권한 목록 조회 V2
 * @returns HeimdallResponseListString OK
 * @throws ApiError
 */
export function listWorkspaceUserPermissions(
    {
        tenantId,
        workspaceId,
    }: {
        tenantId: number;
        workspaceId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<HeimdallResponseListString> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/permissions/v2',
            query: {
                tenantId: tenantId,
                workspaceId: workspaceId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * @deprecated
 * 사용자 권한 목록 조회
 * @returns HeimdallResponseListString OK
 * @throws ApiError
 */
export function listPermissions(requestConfig: RequestConfig = {}): AxiosPromise<HeimdallResponseListString> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/permissions/v1',
        }),
        ...requestConfig,
    });
}
